


// Modal.js
import React from 'react';

const Modal = ({ show, children, handleClose }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="modal">
            <div className="modal-content">
            <br></br>
                
                {children}
            </div>
        </div>
    );
};

export default Modal;
