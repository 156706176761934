import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Create from './auth/Create';
import Influencer from './auth/Influencer';
import SigninForm from './auth/SigninForm'; // Import other components as needed
import ResetPassword from './auth/ResetPassword';
import RequestPwrd from './auth/RequestPwrd';
import VerifyEmailMsg from './auth/VerifyEmailMsg';
import VerifyEmailHandler from './auth/VerifyEmailHandler';
import EmailisVerifiedMsg from './auth/EmailisVerifiedMsg';
import Dashboardb from './pages/brands/Dashboardb';
import SignHome from './pages/SignHome';
import BrandsQ from './pages/brands/BrandsQ';
import ProtectedRoute from './components/ProtectedRoute';
import CredPrice from './pages/brands/CredPrice';
import Credsdesc from './pages/brands/Credsdesc';
import { AuthProvider } from './auth/authContext';
import Unauthorized from './auth/Unauthorized';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/neuhome" element={<SignHome />} /> {/* Set SignHome as the default route */}
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/passrequest" element={<RequestPwrd />} />
            <Route path="/resetpass" element={<ResetPassword />} />
            <Route path="/emailmsg" element={<VerifyEmailMsg />} />
            <Route path="/VerifyEmailHandler" element={<VerifyEmailHandler />} />
            <Route path="/accountverified" element={<EmailisVerifiedMsg />} />
            <Route path="/login" element={<SigninForm />} />
            <Route path="/brandsignup" element={<Create />} />
            <Route path="/creatorsignup" element={<Influencer />} />
            <Route path="/brandaccount/passrequest" element={<RequestPwrd />} />
            <Route path="/brandsignup/emailmsg" element={<VerifyEmailMsg />} />
            
            {/* Protected Routes */}
            <Route
              path="/onboardbrands"
              element={
                <ProtectedRoute>
                  <BrandsQ />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboardb"
              element={
                <ProtectedRoute>
                  <Dashboardb />
                </ProtectedRoute>
              }
            />

            <Route
              path="/Credsdesc"
              element={
                <ProtectedRoute>
                  <Credsdesc />
                </ProtectedRoute>
              }
            />
           
           <Route path="/CredPrice" 
           element={
            <ProtectedRoute>
           <CredPrice /> 
           </ProtectedRoute>
            }
        />
            {/* Redirect any unmatched routes to /neuhome */}
            <Route path="*" element={<Navigate to="/neuhome" />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
