
//Pricing for CREDITZ

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './creds.css'; 

function CredPrice () {
  
    const navigate = useNavigate();
  
    useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
};

  return (
 
   
   <div className="CREDITZ-intro">
    <h2 style={{ fontSize: '30px', color: '#fc8320',textAlign: 'center', }}>CREDITZ Pricing Plans</h2>
    <p className="creditz-details">Choose a CREDITZ package that fits your budget.
    After payment, you will be emailed options to activate your CREDITZ.</p>
    <p style={{ color: '#fc8320' }}>
        <a href="/Credsdesc" style={{ color: '#fc8320', textDecoration: 'underline', marginTop: 5, }}>View what you get with CREDITZ.</a>
      </p>
     <div className="pricing-tables-container">
      <stripe-pricing-table
        pricing-table-id="prctbl_1NrkMYG3BlD50Xxq9DeQoeyS"
        publishable-key="pk_live_51LC6a5G3BlD50XxqCJaptaHIFZZUdxObFg0mLsMSPfzOPbnn7Jyq5jbu22qplCqg1hvl4P5gGknFrsKYMcvP54UR00SzRuzGiu"
      ></stripe-pricing-table>

      <stripe-pricing-table
        pricing-table-id="prctbl_1OQ9sJG3BlD50XxqFUJfd3r6"
        publishable-key="pk_live_51LC6a5G3BlD50XxqCJaptaHIFZZUdxObFg0mLsMSPfzOPbnn7Jyq5jbu22qplCqg1hvl4P5gGknFrsKYMcvP54UR00SzRuzGiu"
      ></stripe-pricing-table>

      <stripe-pricing-table
        pricing-table-id="prctbl_1OQ9yFG3BlD50XxqYGBu3RCZ"
        publishable-key="pk_live_51LC6a5G3BlD50XxqCJaptaHIFZZUdxObFg0mLsMSPfzOPbnn7Jyq5jbu22qplCqg1hvl4P5gGknFrsKYMcvP54UR00SzRuzGiu"
      ></stripe-pricing-table>

      <stripe-pricing-table
        pricing-table-id="prctbl_1OylQdG3BlD50XxqMABhn5h9"
        publishable-key="pk_live_51LC6a5G3BlD50XxqCJaptaHIFZZUdxObFg0mLsMSPfzOPbnn7Jyq5jbu22qplCqg1hvl4P5gGknFrsKYMcvP54UR00SzRuzGiu"
      ></stripe-pricing-table>
        
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}>
        <button onClick={() => handleNavigation('/Credsdesc')} className="dashboardb-button">Creditz Page</button>
        <button onClick={() => handleNavigation('/dashboardb')} className="credpage-button">Back to Dashboard</button>
      </div>
    </div>
    
  );
}

export default CredPrice;
