import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import Modal from './Modal';
import { useAuth } from '../auth/authContext';

/**
 * Navbar component providing navigation functionality.
 * Displays different buttons and options based on current route.
 */
const Navbar = () => {
  const { user, emailVerified } = useAuth();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isSigningOut, setIsSigningOut] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);
  const avatarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        avatarRef.current &&
        !avatarRef.current.contains(event.target)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  const handleSignOut = () => {
    setShowModal(true);
    setShowSuccessMessage(false);
  };

  const confirmSignOut = async () => {
    setIsSigningOut(true);
    try {
      await signOut(auth);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowModal(false);
        setIsSigningOut(false);
        navigate('/login', { state: { showSuccessMessage: true } });
      }, 2000);
    } catch (error) {
      console.error('Error signing out:', error);
      setIsSigningOut(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowSuccessMessage(false);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const shouldShowAvatar = () => {
    return user && emailVerified ;
  };

  const shouldShowLoginButton = () => {
    return !user && ['/neuhome', '/resetpass', '/passrequest'].includes(location.pathname);
  };

  const shouldShowHomeButton = () => {
    const pathsToCheck = ['/login', '/brandsignup', '/creatorsignup'];
    return (!user && pathsToCheck.includes(location.pathname)) || (user && emailVerified && location.pathname === '/login');
  };
  
  

  const RenderButton = ({ path, text, className }) => (
    <button onClick={() => handleNavigation(path)} className={className}>
      {text}
    </button>
  );

  return (
    <nav className="navbar" aria-label="Main Navigation">
      <div>
        <a href="https://www.neufluence.com" target="_blank" rel="noopener noreferrer">
          <img src='./assets/images/Neu_beta.png' alt="Company Logo" className="logo" />
        </a>
      </div>

      <div className="nav-right">
        {shouldShowAvatar() ? (
          <div>
            <img
              src={user?.photoURL || "./NEU-LOGO.ico"}
              alt="User Avatar"
              className="nav-avatar"
              onClick={toggleDropdown}
              role="button"
              aria-label="User menu"
              ref={avatarRef}
            />
            {dropdownVisible && (
              <div ref={dropdownRef} className="nav-dropdown" aria-label="User dropdown">
                <Link to="/brandaccount">My Account</Link>
                <Link to="/dashboardb">My Dashboard</Link>
                <Link to="/settings">Settings</Link>
                <button onClick={handleSignOut} className="sign-out-link">
                  Sign Out
                </button>
              </div>
            )}
          </div>
        ) : (
          <>
            {shouldShowLoginButton() && <RenderButton path="/login" text="LOG IN" className="login-button" />}
            {shouldShowHomeButton() && <RenderButton path="/neuhome" text="HOME" className="signup-button" />}
          </>
        )}
      </div>

      <Modal show={showModal} onClose={handleCloseModal}>
        {showSuccessMessage ? (
          <p>You have been signed out successfully.</p>
        ) : (
          <>
            <p>You are about to sign out. Are you sure?</p>
            <button onClick={confirmSignOut} className="confirmout-button" disabled={isSigningOut}>
              {isSigningOut ? 'Signing Out...' : 'Confirm Sign Out'}
            </button>
          </>
        )}
        <br />
        <button onClick={handleCloseModal} className="close-button" disabled={isSigningOut}>
          Close
        </button>
      </Modal>
    </nav>
  );
};

export default Navbar;
