

import { useState } from 'react';
import { auth } from '../firebase';
import { sendEmailVerification } from 'firebase/auth';
import { Link } from 'react-router-dom';

const Unauthorized = () => {
    const [message, setMessage] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);


    const handleResendEmail = async () => {
        if (auth.currentUser) {
          try {
            await sendEmailVerification(auth.currentUser);
            setMessage("Verification email resent. Please check your inbox.");
            setIsButtonDisabled(true);
            setTimeout(() => setIsButtonDisabled(false), 40000); // 40 seconds
          } catch (error) {
            console.error("Error resending verification email: ", error);
            setMessage("Error resending email. Please try again later.");
          }
        }
        };
    return (
      <div className="emailMessage form-style">
          <h1 style={{ color: '#fc8320' }}>Unauthorized Access</h1>
       
        <p>You do not have permission to view this page. Check to see if you're logged in.</p>
        <br></br>
        <p>If are logged in, then you may not have confirmed your email. Click Resend Email button below.</p>
        <p style={{ color: '#fc8320' }}>Once you verify your email, you will have access to this page.</p>
        {message && <p style={{ color: 'green' }} className="message">{message}</p>}

        <button type="button" onClick={handleResendEmail} disabled={isButtonDisabled}>Resend Email</button>
        

        <br />
      <p>Still having trouble? Contact us at <span className="email-style">signup@neufluence.com</span>.</p>

      <Link to="/login">Go to Login</Link>
      </div>
    );
  };
  
  export default Unauthorized;

