
// EmailisVerifiedMsg.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { verifyPasswordResetCode, applyActionCode } from 'firebase/auth';
import LoadingSpinner from '../components/LoadingSpinner';
import { useAuth } from '../auth/authContext';


const EmailisVerifiedMsg = () => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const { refreshUser } = useAuth();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get('mode');
    const oobCode = queryParams.get('oobCode');

    if (!mode || !oobCode) {
      setError('Invalid or expired action code.');
      setIsLoading(false);
      return;
    }

    if (mode === 'resetPassword') {
      verifyPasswordResetCode(auth, oobCode)
        .then(() => {
          navigate(`/resetpass?oobCode=${oobCode}`);
        })
        .catch(() => {
          setError('Invalid or expired action code.');
          setIsLoading(false);
        });
    } else if (mode === 'verifyEmail') {
      applyActionCode(auth, oobCode)
        .then(async () => {
          setMessage('Your email is verified!');
          setIsLoading(false); // Show the success message first
          
          await refreshUser(); // Refresh the user's authentication status

          setMessage('Your email is verified and your authentication status has been updated!');

          const timeoutId = setTimeout(() => navigate('/login'), 3000); // Redirect after 3 seconds
          return () => clearTimeout(timeoutId); // Cleanup function to clear the timeout if the component unmounts
        })
        .catch(() => {
          setError('Invalid or expired action code.');
          setIsLoading(false);
        });
    } else {
      setError('Invalid action mode.');
      setIsLoading(false);
    }
  }, [location, navigate, refreshUser]);

    return (
        <div className="form-style">
            {isLoading ? (
             <LoadingSpinner />
         ) : (
                 
        <div>
          <h1>Your email is verified!</h1>
     
     
          <p style={{ fontSize: '20px', color: '#2c3e50' }}>You are now being redirected to your account.</p>

            <br></br>
            <br></br>
         
        </div>
         )}
    </div>
    );
};  

export default EmailisVerifiedMsg;
