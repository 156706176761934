import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/authContext';
import Navbar from './Navbar';
import Modal from './Modal';
import LoadingSpinner from './LoadingSpinner';

const ProtectedRoute = ({ children, requireEmailVerified = false }) => {
  const { user, emailVerified, loading } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (requireEmailVerified && user && !emailVerified) {
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        navigate('/verify-email'); // Redirect to the email verification page
      }, 3000); // Adjust the delay as needed
    }
  }, [requireEmailVerified, user, emailVerified, navigate]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!user) {
    // If the user is not authenticated, redirect to the login page
    return <Navigate to="/login" />;
  }

  if (requireEmailVerified && !emailVerified) {
    // Show modal and then redirect
    return (
      <>
        <Navbar />
        <Modal
          show={showModal}
          message="You have not confirmed your email. We are redirecting you to confirm your email before proceeding."
          onClose={() => setShowModal(false)}
          showSpinner={true}
        >
          <LoadingSpinner />
        </Modal>
      </>
    );
  }

  // If the user is authenticated and email verification is not required or already verified, render the children components
  return (
    <>
      <Navbar />
      {children}
    </>
  );
};

export default ProtectedRoute;
