
// This is the code that verifies the user's email using Firebase auth.


import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { applyActionCode } from 'firebase/auth';
import LoadingSpinner from '../components/LoadingSpinner';
import { auth} from '../firebase';

    const VerifyEmailHandler = () => {
         const [message, setMessage] = useState('Verifying your email...');
         const [isLoading, setIsLoading] = useState(true); // Loading state
         const navigate = useNavigate();
         
    
     // Function to translate Firebase error codes to user-friendly messages

     const getErrorMessage = (error) => {
        switch (error.code) {
            case 'auth/invalid-action-code':
                return 'The verification link is invalid. It might have already been used or expired.';
            case 'auth/user-disabled':
                return 'The user account has been disabled by an administrator.';
            case 'auth/user-not-found':
                return 'No user found corresponding to the verification request.';
            // Add more cases as needed for different error codes
            default:
                return 'An unexpected error occurred. Please try again later.';
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('oobCode');

        // Validate the oobCode before using it

        if (!code) {
            setMessage('No verification code found in the URL.');
            setIsLoading(false); // Stop loading when no code is found
            return;
        }

        const verifyEmail = async () => {
            try {
                await applyActionCode(auth, code);
                setIsLoading(false);

                // Ensure the user is logged in after verification
                const user = auth.currentUser;

                if (user) {
                    // Navigate to the EmailisVerifiedMsg page
                    navigate('/emailverified');
                } else {
                    setMessage('User not authenticated.');
                }
            } catch (error) {
                // Log the error for debugging purposes
                console.error('Email verification error:', error);

                // If there's an error, update the message to reflect it
                setMessage(getErrorMessage(error));
                setIsLoading(false); // Stop loading when an error occurs
            }
        };

        verifyEmail();
    }, [navigate]);



            return (
                <div className="verify-email-handler">
                    {isLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <div>
                            <h1>Email Verification Status</h1>
                            <p>{message}</p>
                            {/* Additional options for the user in case of an error */}
                        </div>
                        )}
                    </div>
                    );
                };

export default VerifyEmailHandler;
