import * as Yup from 'yup';

// Email validation schema
const emailValidation = Yup.string()
  .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/, 'Invalid email format.')
  .required('Email is required.');

// Password validation schema
const passwordValidation = Yup.string()
  .min(7, 'Password must be at least 7 characters long.')
  .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
  .matches(/[0-9]/, 'Password must contain at least one number.')
  .matches(/[a-zA-Z]/, 'Password must contain at least one character.')
  .required('Password is required.');

// Confirm password validation schema
const confirmPasswordValidation = Yup.string()
  .oneOf([Yup.ref('password'), null], 'Passwords do not match.')
  .required('Password confirmation is required.');

// Signup schema
export const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required.'),
  lastName: Yup.string().required('Last name is required.'),
  email: emailValidation,
  password: passwordValidation,
  confirmPassword: confirmPasswordValidation,
});

// Sign-in schema
export const SignInSchema = Yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
});

// Brand Onboarding schema
export const BrandOnboardingSchema = Yup.object().shape({
  companyName: Yup.string().required('Company Name is required.'),
  website: Yup.string().url('Invalid website format.').required('Website is required.').default('https://'),
  title: Yup.string().required('Title is required.'),
  industry: Yup.string().required('Industry is required.'),
  companySize: Yup.string().required('Company size is required.'),
  phoneNumber: Yup.string().matches(/^[0-9]+$/, 'Invalid phone number.'),
  companyMulti: Yup.string().required('Please select an option.'),
  multiEntrepreneur: Yup.string().when('companyMulti', {
    is: 'yes',
    then: Yup.string().required('Multicultural category is required.'),
    otherwise: Yup.string().notRequired(),
  }),
  nonProfit: Yup.string().required('Please select an option.'),
  npCause: Yup.string().when('nonProfit', {
    is: 'yes',
    then: Yup.string().required('Cause is required for non-profit organizations.'),
    otherwise: Yup.string().notRequired(),
  }),
  selectCountry: Yup.string().required('Country is required.'),
  selectState: Yup.string().required('State is required.'),
  selectCity: Yup.string().required('City is required.'),
  referredBy: Yup.string().notRequired(),
});
