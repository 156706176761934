

import React from 'react';
import { Field, ErrorMessage } from 'formik';

/**
 * IndustrySelector component for selecting business industry.
 */
const IndustrySelector = () => {
  return (
    <div className="industry-container">
      <label htmlFor="industry">
        What industry is your business in? <span style={{ color: '#fc8320' }}>*</span>
        <Field as="select" id="industry" name="industry" required>
          <option value="">Select your business industry</option>
          <option value="automotive">Automotive</option>
          <option value="beauty">Beauty</option>
          <option value="business">Business</option>
          <option value="entertainment">Entertainment</option>
          <option value="fashion">Fashion</option>
          <option value="food">Food</option>
          <option value="gaming">Gaming</option>
          <option value="health">Health</option>
          <option value="homeImprovement">Home Improvement</option>
          <option value="lifestyle">Lifestyle</option>
          <option value="other">Other</option>
          <option value="parenting">Parenting</option>
          <option value="pet">Pet</option>
          <option value="religion">Religion</option>
          <option value="sportsFitness">Sports/Fitness</option>
          <option value="tech">Tech</option>
          <option value="travel">Travel</option>
          <option value="wellness">Wellness</option>
        </Field>
      </label>
      <ErrorMessage name="industry" component="div" className="error" style={{ color: 'red' }} />
    </div>
  );
};

export default IndustrySelector;
