import { useState } from 'react';
import { auth } from '../firebase';
import { sendEmailVerification } from 'firebase/auth';

const VerifyEmailMsg = () => {
  const [message, setMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleResendEmail = async () => {
    if (auth.currentUser) {
      try {
        await sendEmailVerification(auth.currentUser);
        setMessage("Verification email resent. Please check your inbox.");
        setIsButtonDisabled(true);
        setTimeout(() => setIsButtonDisabled(false), 40000); // 40 seconds
      } catch (error) {
        console.error("Error resending verification email: ", error);
        setMessage("Error resending email. Please try again later.");
      }
    }
  };

  return (
    <div className="emailMessage form-style">
      <h2 style={{ color: '#fc8320' }}>Confirm your Email</h2>
      <p>To activate your account, we need to reconfirm your email. Please check your email account (and spam!) for our verification email.</p>
      <p style={{ color: '#fc8320' }}>If you didn’t receive an email, wait a few moments and click the Resend Email button.</p>
      {message && <p style={{ color: 'green' }} className="message">{message}</p>}
      <button type="button" onClick={handleResendEmail} disabled={isButtonDisabled}>Resend Email</button>
      <br />
      <p>Still having trouble? Contact us at <span className="email-style">signup@neufluence.com</span>.</p>
    </div>
  );
};

export default VerifyEmailMsg;
