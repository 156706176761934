import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import '../index.css';
import Modal from '../components/Modal';
import LoadingSpinner from '../components/LoadingSpinner';
import * as Yup from 'yup';
import { useAuth } from '../auth/authContext'; // Import the useAuth hook

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Required')
});

const SigninForm = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth(); // Destructure setUser and user from useAuth
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [authError, setAuthError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const displayModalAndNavigate = (message, path) => {
    setIsModalOpen(true);
    setModalMessage(message);
    setTimeout(() => {
      setIsModalOpen(false);
      navigate(path, { replace: true });
    }, 4000);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const { email, password } = values;
    setAuthError('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      setUser(user);

      await updateDoc(doc(db, 'users', user.uid), {
        lastSignon: serverTimestamp()
      });

      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();

      if (userData) {
        if (user.emailVerified && userData.userType === 'brand') {
          if (!userData.hasCompletedOnboarding) {
            displayModalAndNavigate('You are now being directed to complete the onboarding process. Once completed, you will be redirected to your dashboard.', '/onboardbrands');
          } else {
            navigate('/dashboardb', { replace: true });
          }
        }
      }
    } catch (error) {
      console.error('Sign-in error:', error.message);
      setAuthError(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="loginForm form-style">
      <h2>Log In to Your Neufluence Account</h2>
      {authError && <div style={{ color: 'red' }}>{authError}</div>}

      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={SignInSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div>
              <label htmlFor="email">Email Address</label>
              <Field
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                required
              />
              <ErrorMessage name="email" component="div" style={{ color: 'red' }} />
            </div>

            <div>
              <label htmlFor="password">Password</label>
              <Field
                type={showPassword ? 'text' : 'password'}
                name="password"
                id="password"
                autoComplete="current-password"
                required
              />
              <ErrorMessage name="password" component="div" style={{ color: 'red' }} />
            </div>

            <label>
              <input
                type="checkbox"
                checked={showPassword}
                onChange={toggleShowPassword}
                id="showPassword"
              />
              Show Password
            </label>

            <button type="submit" disabled={isSubmitting}>Sign In</button>

            <div className="forgot-password-link">
              <a href="/passrequest" className="forgot-password-link">Forgot password?</a>
            </div>
          </Form>
        )}
      </Formik>

      <Modal show={isModalOpen} handleClose={() => setIsModalOpen(false)}>
        <div>
          <p>{modalMessage}</p>
          <LoadingSpinner aria-label="Loading..." />
        </div>
      </Modal>
    </div>
  );
};

export default SigninForm;
