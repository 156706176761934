import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../firebase';
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { useAuth } from '../auth/authContext';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Modal from '../components/Modal';
import LoadingSpinner from '../components/LoadingSpinner';

/**
 * ResetPassword component handles the password reset process.
 * It verifies the password reset code, allows the user to enter a new password,
 * and handles the password reset operation with Firebase Authentication.
 */

const ResetPassword = () => {
  const { user, emailVerified } = useAuth(); // Get user and emailVerified from useAuth
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Ensure user is authenticated and email is verified
    if (!user || !emailVerified) {
      setError('You must be logged in and have a verified email to reset your password.');
      setIsLoading(false);
      return;
    }

    // Parse URL parameters
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get('mode');
    const oobCode = queryParams.get('oobCode');

    if (mode === 'resetPassword' && oobCode) {
      verifyPasswordResetCode(auth, oobCode)
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setError('Invalid or expired action code.');
          setIsLoading(false);
        });
    } else {
      setError('Invalid URL parameters.');
      setIsLoading(false);
    }
  }, [user, emailVerified, location]);

  const handleResetPassword = async (actionCode, newPassword) => {
    try {
      await confirmPasswordReset(auth, actionCode, newPassword);
    } catch (err) {
      throw err;
    }
  };

  const showSuccessModal = () => {
    setSuccessMessage('Password has been reset successfully.');
    setShowModal(true);
    setIsLoading(true);
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(7, 'Password must be at least 7 characters long.')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
      .matches(/[0-9]/, 'Password must contain at least one number.')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match.')
      .required('Confirm Password is required')
  });

  return (
    <div className="form-style">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Formik
          initialValues={{ password: '', confirmPassword: '' }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setError('');
            setSuccessMessage('');
            setIsLoading(true);
            const actionCode = new URLSearchParams(location.search).get('oobCode');
            try {
              await handleResetPassword(actionCode, values.password);
              showSuccessModal();
              setTimeout(() => navigate('/login'), 3000);
            } catch (err) {
              setError("Failed to reset password. " + err.message);
              setIsLoading(false);
            } finally {
              resetForm();
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <h2>Reset Your Password</h2>
              {error && <div style={{ color: 'red' }}>{error}</div>}
              {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}

              <label>Password
                <Field
                  type="password"
                  name="password"
                  autoComplete="new-password"
                  placeholder="Password"
                />
                <ErrorMessage name="password" component="div" style={{ color: 'red' }} />
              </label>

              <label>Confirm Password
                <Field
                  type="password"
                  name="confirmPassword"
                  autoComplete="new-password"
                  placeholder="Confirm Password"
                />
                <ErrorMessage name="confirmPassword" component="div" style={{ color: 'red' }} />
              </label>

              <button type="submit" disabled={isSubmitting || isLoading}>
                {isSubmitting || isLoading ? <LoadingSpinner /> : "Reset Password"}
              </button>
            </Form>
          )}
        </Formik>
      )}

      <Modal show={showModal} handleClose={() => setShowModal(false)}>
        <p>{successMessage}</p>
        <LoadingSpinner aria-label="Loading..." />
      </Modal>
    </div>
  );
}

export default ResetPassword;
