import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './account.css';
import '../components/navbar.css';

const SignHome = () => {
  const [selectedOption, setSelectedOption] = useState(''); // State to track the selected option
  const [errorMessage, setErrorMessage] = useState(''); // State to track error messages
  const navigate = useNavigate();

  // Function to handle selection change
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value); // Update selected option state
    setErrorMessage(''); // Clear any previous error message
  };

  // Function to handle button click and redirect based on selected option
  const handleButtonClick = (event) => {
    event.preventDefault(); // Prevent default form submission
    try {
      if (selectedOption === 'brand') {
        navigate('/brandsignup'); // Redirect to Brand component
      } else if (selectedOption === 'influencer') {
        navigate('/creatorsignup'); // Redirect to Influencer component
      } else {
        // Handle case where no option is selected
        throw new Error('Please select a role.');
      }
    } catch (error) {
      console.error('Navigation error:', error.message); // Log error to console
      setErrorMessage(error.message); // Set error message to display to the user
    }
  };

  return (
    <div className="sign-home">
      <form>
        <div className="container-style">
          <h2>Welcome to Neufluence!</h2>
          <p>This is the initial step of the sign up process to create your account.</p>
          <p>Select the account type below to get started.</p>

          {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error message if any */}

          <select
            name="type"
            className="custom-select signup"
            onChange={handleSelectChange}
            value={selectedOption}
          >
            <option value="" disabled>Select account type</option>
            <option value="brand">Brand</option>
            <option value="influencer">Creator</option>
          </select>

          <button onClick={handleButtonClick} className="submit-button">GET STARTED</button>

          <div className="cta-links">
            <p>Already signed up? Click <a href="/login">here</a> to access your account.</p>
          </div>

          <div className="term-links">
            By creating an account, you agree to our
            <a href="https://neufluence.com/terms-of-use/" target="_blank" rel="noopener noreferrer">
              Terms of Service,
            </a>
            <a href="https://neufluence.com/privacy_policy/" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            and our default notifications settings.
          </div>
        </div>
      </form>
      <div className="image-container">
        <img src='./assets/images/Join_image.png' alt="Join Creator" />
      </div>
    </div>
  );
};

export default SignHome;
