import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebase';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import './questionnaire.css';
import Modal from '../../components/Modal';
import ProgressBar from './ProgressBar';
import './ProgressBar.css';
import LoadingSpinner from '../../components/LoadingSpinner';
import LocationSelector from '../../components/LocationSelector';

import IndustrySelector from '../../components/IndustrySelector';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';



const BrandsQ = () => {
const navigate = useNavigate();
const [isModalOpen, setIsModalOpen] = useState(false);
const [successMessage, setSuccessMessage] = useState('');
const [isLoading, setIsLoading] = useState(false);

const initialValues = {
companyName: '',
title: '',
website: 'https://',
companySize: '',
country: '',
state: '',
city: '',
industry: '',
phoneNumber: '',
referredBy: '',
};

const validationSchema = Yup.object().shape({
companyName: Yup.string().required('Company Name is required.'),
website: Yup.string().url('Invalid website format.').required('Website is required.'),
title: Yup.string().required('Title is required.'),
companySize: Yup.string().required('Company size is required.'),
country: Yup.string().required('Country is required.'),
state: Yup.string().required('State is required.'),
city: Yup.string().required('City is required.'),
industry: Yup.string().required('Industry is required.'),
phoneNumber: Yup.string().matches(/^[0-9]+$/, 'Invalid phone number.'),
referredBy: Yup.string().notRequired(),
});

const handleSubmit = async (values, { setSubmitting, setErrors, resetForm }) => {
setIsLoading(true);
const user = auth.currentUser;
if (!user) {
setErrors({ submit: 'You must be signed in to submit this form.' });
setIsLoading(false);
setSubmitting(false);
return;
}

const formattedWebsite = values.website.startsWith('http://') || values.website.startsWith('https://')
? values.website
: `https://${values.website}`;

const formData = {
...values,
website: formattedWebsite,
createdAt: serverTimestamp(),
hasCompletedOnboarding: true,
};

const userRef = doc(db, 'users', user.uid);

try {
const userDoc = await getDoc(userRef);
if (userDoc.exists()) {
const userData = userDoc.data();
await setDoc(userRef, {
...userData,
...formData,
hasCompletedOnboarding: true,
createdAt: userData.createdAt || new Date(),
});

setSuccessMessage('Your onboarding is complete! Thank you. We are redirecting you to the dashboard.');
setIsModalOpen(true);
setTimeout(() => {
setIsModalOpen(false);
navigate('/Dashboardb');
}, 4000);

resetForm(); // Reset form fields after successful submission
} else {
console.error('User document not found in users collection');
}
} catch (err) {
console.error('Error updating document in Firestore: ', err.message);
setErrors({ submit: 'Failed to submit. Please try again.' });
} finally {
setIsLoading(false);
setSubmitting(false);
}
};

return (
<div className="questionnaire-container">
<div className="createhead">
<h3>Brand Onboarding</h3>
</div>
<h3 className="questionnaire-style-heading">This is the Final Step!</h3>
<p>Answer the questions below about your business. Once submitted, you will be directed to your dashboard.</p>

<ProgressBar progress={1} />

<Formik
initialValues={initialValues}
validationSchema={validationSchema}
onSubmit={handleSubmit}
>
{({ isSubmitting, errors }) => (
<Form className="questionnaire-style">
{errors.submit && <p style={{ color: 'red' }} className="error">{errors.submit}</p>}

<div className="scrollable-content">
<label htmlFor="companyName">
Company Name <span style={{ color: '#fc8320' }}>*</span>
<Field type="text" id="companyName" name="companyName" required />
</label>
<ErrorMessage name="companyName" component="div" className="error" style={{ color: 'red' }} />

<LocationSelector /> {/* Integrate the new component */}

<label htmlFor="website">
Business Website <span style={{ color: '#fc8320' }}>*</span>
<Field type="url" id="website" name="website" placeholder="https://yourcompany.com" required />
</label>
<ErrorMessage name="website" component="div" className="error" style={{ color: 'red' }} />

<label htmlFor="title">
Title <span style={{ color: '#fc8320' }}>*</span>
<Field type="text" id="title" name="title" required />
</label>
<ErrorMessage name="title" component="div" className="error" style={{ color: 'red' }} />

<label htmlFor="phoneNumber">
What is your phone number? <span style={{ color: '#fc8320' }}>*</span>
<Field type="text" id="title" name="phoneNumber" />
</label>
<ErrorMessage name="title" component="div" className="error" style={{ color: 'red' }} />


<IndustrySelector /> 

<label htmlFor="companySize">
What is the size of your business? <span style={{ color: '#fc8320' }}>*</span>
<Field as="select" id="companySize" name="companySize" required>
<option value="">Select your business size</option>
<option value="0-10">0-10 employees</option>
<option value="11-50">11-50 employees</option>
<option value="51-150">51-150 employees</option>
<option value="151+">151+ employees</option>
<option value="other">Other</option>
</Field>
</label>
<ErrorMessage name="companySize" component="div" className="error" style={{ color: 'red' }} />

<label htmlFor="referredBy">
How did you hear about us?
<Field as="select" id="referredBy" name="referredBy">
<option value="">Select a referral source</option>
<option value="google">Google</option>
<option value="other">Other</option>
<option value="referral">Referral</option>
<option value="socialMedia">Social Media</option>
<option value="techstars">Techstars</option>
</Field>
</label>
</div>

<button type="submit" disabled={isSubmitting || isLoading}>Sign In</button>
</Form>
)}
</Formik>

<Modal show={isModalOpen} handleClose={() => setIsModalOpen(false)}>
<div>
<p>{successMessage}</p>
<LoadingSpinner aria-label="Loading..." />
</div>
</Modal>
</div>
);
};

export default BrandsQ;




































