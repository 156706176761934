import React, { useState, useEffect } from 'react';
import { useFormikContext, Field } from 'formik';
import { Country, State, City } from 'country-state-city';

const LocationSelector = () => {
  const { setFieldValue, values } = useFormikContext();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const sortedCountries = Country.getAllCountries().sort((a, b) => {
      if (a.name === 'United States' || a.name === 'Canada') return -1;
      if (b.name === 'United States' || b.name === 'Canada') return 1;
      return a.name.localeCompare(b.name);
    });
    setCountries(sortedCountries);
  }, []);

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setFieldValue('country', selectedCountry);
    setStates(State.getStatesOfCountry(selectedCountry));
    setFieldValue('state', '');
    setCities([]);
    setFieldValue('city', '');
  };

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setFieldValue('state', selectedState);
    setCities(City.getCitiesOfState(values.country, selectedState));
    setFieldValue('city', '');
  };

  return (
    <div className="location-container">
      {/* Country Dropdown */}
      <label htmlFor="country">
        Select your country location. <span style={{ color: '#fc8320' }}>*</span>
        <Field as="select" 
        id="country" 
        name="country" 
        required onChange={handleCountryChange}>
          <option value="">Select a country</option>
          {countries.map((country) => (
            <option key={country.isoCode} value={country.isoCode}>
              {country.name}
            </option>
          ))}
        </Field>
      </label>

      {/* State Dropdown - Conditionally Rendered */}
      {states.length > 0 && (
        <label htmlFor="state">
          Enter your state, province, or region. <span style={{ color: '#fc8320' }}>*</span>
          <Field as="select" 
          id="state" 
          name="state" 
          required onChange={handleStateChange}>
            <option value="">Select a state/region</option>
            {states.map((state) => (
              <option key={state.isoCode} value={state.isoCode}>
                {state.name}
              </option>
            ))}
          </Field>
        </label>
      )}

      {/* City Dropdown - Conditionally Rendered */}
      {cities.length > 0 && (
        <label htmlFor="city">
          Enter your city. <span style={{ color: '#fc8320' }}>*</span>
          <Field as="select" id="city" name="city" required>
            <option value="">Select a city</option>
            {cities.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </Field>
        </label>
      )}
    </div>
  );
};

export default LocationSelector;
