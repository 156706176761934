import React, { useEffect } from 'react';
import './dashboardb.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/authContext';

const Dashboardb = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      navigate('/unauthorized');
    }
  }, [user, navigate]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return user ? (
    <div className="dashboard-container">
      <div className="dashboard-box">
        <div className="createhead">
          <h3>Welcome To Your Dashboard</h3>
          <p>Select an option below.</p>
        </div>
        <br />
        <div className="button-group">
          <div className="button-row">
            <button className="dashboard-button creditz" onClick={() => handleNavigation('/Credsdesc')}>
              Buy CREDITZ
            </button>
            <button className="dashboard-button fullService" onClick={() => handleNavigation('/searchCreators')}>
              Full Service Plans
            </button>
          </div>
          <div className="button-row">
            <button className="dashboard-button curatedCreated" onClick={() => handleNavigation('/Sample')}>
              Sample Curated Creators
            </button>
            <button className="dashboard-button socialImpact" onClick={() => handleNavigation('/payRateCalculator')}>
              Social Impact
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null; // Fix the return statement
};

export default Dashboardb;
