import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { SignupSchema } from '../utils/validationSchemas';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import Modal from '../components/Modal';
import LoadingSpinner from '../components/LoadingSpinner';
import '../pages/account.css';
import ReCAPTCHA from 'react-google-recaptcha';

const Create = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [error, setError] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false); // State to manage password visibility
  const [recaptchaValue, setRecaptchaValue] = React.useState(''); // State to manage reCAPTCHA value

  // Function to handle form submission
  const handleSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {
    if (!recaptchaValue) {
      setError('Please complete the reCAPTCHA.');
      setSubmitting(false);
      return;
    }

    // Check honeypot field
    if (values.hiddenField) {
      setError('Bot detected.');
      setSubmitting(false);
      return;
    }

    setError(''); // Reset the error message at the start of submission
    setSubmitting(true); // Start of submission process

    try {
      // Create user
      const { user } = await createUserWithEmailAndPassword(auth, values.email, values.password);
      console.log('User created:', user);

      // Add user data to Firestore
      await setDoc(doc(db, 'users', user.uid), {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        userType: 'brand',
        hasCompletedOnboarding: false, // Default value
        createdAt: serverTimestamp(),
        lastSignon: serverTimestamp()
      });

      // Send verification email
      await sendEmailVerification(user);

      // Provide feedback upon successful submission
      setSuccessMessage('Thank you for signing up! The next step is to confirm your email.');
      setIsModalOpen(true); // Show modal on success
      setTimeout(() => {
        setIsModalOpen(false); // Hide modal after 4 seconds
        resetForm(); // Reset the form fields
        navigate('/emailmsg');
      }, 4000); // Delay of 4000 milliseconds (4 seconds)
    } catch (err) {
      // Handle errors here (e.g., user creation failed, Firestore operation failed, etc.)
      console.error('Signup error:', err); // Log error to console
      switch (err.code) {
        case 'auth/invalid-email':
          setErrors({ email: 'Invalid email format. Please enter a valid email address.' });
          break;
        case 'auth/email-already-in-use':
          setErrors({ email: 'This email is already in use. Please use a different email address.' });
          break;
        default:
          setError('An error occurred during your signup attempt. Please recheck your entries and try again. If the issue persists, email signup@neufluence.com');
          break;
      }
    } finally {
      setSubmitting(false); // End of submission process
    }
  };

  // Function to toggle password visibility
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Function to handle reCAPTCHA change
  const onRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  // Sign Up Form using Formik
  return (
    <div className="create account-style">
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          confirmPassword: '',
          hiddenField: '' // Honeypot field
        }}
        validationSchema={SignupSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="createhead">
              <h3>Brand Signup</h3>
            </div>
            <h2>Set Up Your Neufluence Account</h2>
            <div className="subhead">
              <p>Complete the form below. After clicking Submit, you will be asked to confirm your email.</p>
            </div>
            <br />

            <div>
              <label htmlFor="firstName">First Name</label>
              <Field type="text" id="firstName" name="firstName" autoComplete="given-name" />
              <ErrorMessage name="firstName" component="div" className="error-message" />
            </div>

            {error && <div style={{ color: 'red' }}>{error}</div>}
            <div>
              <label htmlFor="lastName">Last Name</label>
              <Field type="text" id="lastName" name="lastName" autoComplete="family-name" />
              <ErrorMessage name="lastName" component="div" className="error-message" />
            </div>

            <div>
              <label htmlFor="email">Email Address</label>
              <Field type="email" id="email" name="email" autoComplete="email" />
              <ErrorMessage name="email" component="div" className="error-message" />
            </div>

            <div>
              <label htmlFor="password">Password</label>
              <Field type={showPassword ? "text" : "password"} id="password" name="password" autoComplete="new-password" />
              <ErrorMessage name="password" component="div" className="error-message" />
            </div>

            <div>
              <label htmlFor="confirmPassword">Confirm Password</label>
              <Field type={showPassword ? "text" : "password"} id="confirmPassword" name="confirmPassword" autoComplete="new-password" />
              <ErrorMessage name="confirmPassword" component="div" className="error-message" />
            </div>

            {/* Honeypot Field */}
            <div style={{ display: 'none' }}>
              <label htmlFor="hiddenField">Leave this field blank</label>
              <Field type="text" id="hiddenField" name="hiddenField" />
            </div>

            {/* Show Password Checkbox */}
            <label>
              <input
                type="checkbox"
                checked={showPassword}
                onChange={toggleShowPassword}
                id="showPassword"
              />
              Show Password
            </label>

            {/* reCAPTCHA */}
            <div>
              <ReCAPTCHA
                sitekey="6LftcvopAAAAAIjDtKRd6oYNjRLuLJUFZ3XL9dsp"
                onChange={onRecaptchaChange}
              />
            </div>

            <button className="submit-button" type="submit" disabled={isSubmitting}>
              Create My Account
            </button>

            <div className="term-links">
              By creating an account, you agree to our
              <a href="https://neufluence.com/terms-of-use/" target="_blank" rel="noopener noreferrer">
                Terms of Service,
              </a>
              <a href="https://neufluence.com/privacy_policy/" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a> and our default notifications settings.
            </div>
          </Form>
        )}
      </Formik>

      {/* Modal for successful sign up */}
      <Modal show={isModalOpen} handleClose={() => setIsModalOpen(false)}>
        <div>
          <p>{successMessage}</p>
          <LoadingSpinner aria-label="Loading..." /> {/* Display spinner with accessibility improvement with aria-label */}
        </div>
      </Modal>
    </div>
  );
};

export default Create;
