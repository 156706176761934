import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { auth } from '../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

/**
 * RequestPwrd Component
 * This component renders a form that allows users to request a password reset email.
 * It uses Formik for form management and validation with Yup for schema validation.
 * Firebase authentication is used to send the password reset email.
 */
const RequestPwrd = () => {
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');

  /**
   * useEffect Hook
   * This hook is used to clear the success message after 5 seconds.
   */
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(''), 5000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  /**
   * handleFirebaseErrors Function
   * Handles and sets error messages based on Firebase error codes.
   * @param {string} errorCode - The error code returned by Firebase.
   * @param {string} errorMessage - The error message returned by Firebase.
   */
  const handleFirebaseErrors = (errorCode, errorMessage) => {
    if (errorCode === 'auth/invalid-email') {
      setError("Invalid email address.");
    } else if (errorCode === 'auth/user-not-found') {
      setError("User with this email address does not exist.");
    } else {
      setError(errorMessage);
    }
  };

  /**
   * sendResetEmail Function
   * Sends a password reset email using Firebase authentication.
   * @param {string} email - The email address to send the reset link to.
   * @param {function} resetForm - Function to reset the form fields.
   */
  const sendResetEmail = async (email, resetForm) => {
    const actionCodeSettings = {
      url: 'http://localhost:3000/accountverified?mode=resetPassword', // URL for redirection after email verification
      handleCodeInApp: true,
    };

    try {
      await sendPasswordResetEmail(auth, email, actionCodeSettings);
      setSuccessMessage("Password reset email sent! Please check your inbox.");
      resetForm();
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      handleFirebaseErrors(errorCode, errorMessage);
    }
  };

  /**
   * validationSchema
   * Yup schema for form validation.
   */
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required')
  });

  return (
    <div className="form-style">
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setError('');
          setSuccessMessage('');
          sendResetEmail(values.email, resetForm);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <h2>Request Password</h2>
            <p>Enter your email address and we'll send you a link to reset your password.</p>

            {error && <div style={{ color: 'red' }}>{error}</div>}
            {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}

            <label>Email Address</label>
            <Field
              type="email"
              name="email"
              autoComplete="email"
              required
            />
            <ErrorMessage name="email" component="div" style={{ color: 'red' }} />

            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Sending...' : 'Send Password Reset Link'}
            </button>

            <p>Having trouble? Contact us at <span className="email-style">signup@neufluence.com</span>.</p>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RequestPwrd;
