import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase'; // Ensure db is your Firestore instance

// Create a context for authentication
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [emailVerified, setEmailVerified] = useState(false);
  const [userType, setUserType] = useState(null);
  const [hasCompletedOnboarding, setHasCompletedOnboarding] = useState(false);
  const [isBrandUser, setIsBrandUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastSignon, setLastSignon] = useState(null);

  const fetchUserDetails = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserType(userData.userType); // Corrected to read 'userType' from Firestore
        setHasCompletedOnboarding(userData.hasCompletedOnboarding);
        setIsBrandUser(userData.userType === 'brand' && userData.hasCompletedOnboarding); // Corrected to use 'userType'
      
        // Handle lastSignon as Firebase Firestore Timestamp
        if (userData.lastSignon) {
          setLastSignon(userData.lastSignon.toDate()); // Convert Firestore Timestamp to JavaScript Date
        } else {
          setLastSignon(null);
        }
      }
      
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setEmailVerified(currentUser.emailVerified);
        await fetchUserDetails(currentUser.uid);
      } else {
        setUser(null);
        setEmailVerified(false);
        setUserType(null);
        setHasCompletedOnboarding(false);
        setIsBrandUser(false);
        setLastSignon(null); // Ensure lastSignon is reset when user is not authenticated
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const refreshUser = async () => {
    try {
      console.log('Refreshing user...'); // Debugging statement
      const currentUser = auth.currentUser;
      if (currentUser) {
        await currentUser.reload();
        setUser(auth.currentUser);
        setEmailVerified(auth.currentUser.emailVerified);
        await fetchUserDetails(auth.currentUser.uid);
        console.log('User refreshed:', auth.currentUser); // Debugging statement
      }
    } catch (error) {
      console.error('Error refreshing user:', error); // Debugging statement
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ user, setUser, emailVerified, userType, hasCompletedOnboarding, isBrandUser, lastSignon, loading, error, refreshUser }}>
      {children}
    </AuthContext.Provider>
  );
};
